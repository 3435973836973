import React from 'react'
import queryString from 'query-string'
import {getOnePublic} from '../../api/WidgetApi'
import {MessageList} from '../../app/share/components'
import '../../scss/entry.scss'
import {Spinner} from '@blueprintjs/core'

class WidgetIndex extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      widget: null,
      loading: true
    };
  }

  componentDidMount() {
    var query = queryString.parse(window.location.search);
    getOnePublic(query.id).then((result) => this.setState({widget: result.item, loading: false }));
  }

  render() {

    const { widget, loading } = this.state;

    if (loading) {
      return (
        <div className="full-height grid-row v-align h-align">
          <Spinner size={30} />
        </div>
      )
    }

    if (!widget || !widget.config) return null;

    return (
      widget.type === "sermonlist" ? <MessageList widget={widget}/>
      : null
    );

  }
}

export default WidgetIndex;
